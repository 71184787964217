import React from 'react'

const getInitialsFromName = (name) =>
  name.replace(/[^A-Z]/g, '') || (name[0] && name[0].toUpperCase());

function AvatarPlaceholder(props) {
  return (
    <div className="avatar-placeholder">
      {getInitialsFromName(props.name)}
    </div>
  )
}

export default AvatarPlaceholder
