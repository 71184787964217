import React from 'react'
import './Avatar.css'
import AvatarPlaceholder from './AvatarPlaceholder'

function Avatar(props) {
  let classes = 'avatar';
  if (props.size) classes += ' ' + props.size;

  const handleLoadError = (e) => {
    if (typeof props.onImageLoadError === 'function')
      props.onImageLoadError(e);
  }

  if (!props.avatar) {
    return <AvatarPlaceholder {...props} />
  }

  return (
    <img
      src={props.avatar.replace(/http:\/\//, 'https://')}
      alt={`${props.name}'s avatar`}
      title={props.name}
      className={classes}
      onError={handleLoadError}
    />
  )
}

export default Avatar
