const getCountsOf = (list, param) => {
  return list
    .map(function(el) {
      return el[param];
    })
    .sort()
    .reduce(function(counts, el) {
      if (el === null) {
        return counts;
      }
      if (!counts[el]) {
        counts[el] = 1;
      } else {
        counts[el]++;
      }
      return counts;
    }, {})
}

const getHighestOccurring = (counts) => {
  var keys = Object.keys(counts)
  return keys.reduce(function(prev, curr) {
    return counts[curr] > counts[prev] ? curr : prev;
  }, keys[0]);
}


export const getTopTimezone = (users) => {
  const tzCounts = getCountsOf(users, 'tz')
  const topTz = getHighestOccurring(tzCounts)
  return topTz.replace(/.+\//g, '').replace(/_/g,' ')
}

export const groupIntoColumns = (list = [], itemsPerColumn = 8) => {
  const numColumns = Math.ceil(list.length / itemsPerColumn)
  const columns = Array(numColumns).fill()
  return columns
    .map((_, idx) =>  idx * itemsPerColumn)
    .map((start) => list.slice(start, start + itemsPerColumn))
}
