import React from 'react'
import moment from 'moment-timezone'
import { useQuery } from '@apollo/client';
import Team from '../Team'
import transform from '../../utils/transform'
import { getAPIURL } from '../../utils/urls'
import { GET_TEAM } from '../../queries'
import './Legacy.css';

function AppContainer() {
  const { loading, error, data } = useQuery(GET_TEAM, { errorPolicy: 'all' });
  if (loading) return <p>Loading...</p>;

  if (error) {
    if (data.me) {
      return (
        <p>
          <a href={`${getAPIURL()}/auth/slack`}>Create your team with Slack</a>
        </p>
      )
    } else {
      return (
        <p>
          <a href={`${getAPIURL()}/login/slack`}>Login or create an account w/ Slack</a>
        </p>
      )
    }
  }

  const time = moment()
  const user = {
    ...data.me,
    teams: [data.team]
  }
  const props = {
    user,
    team: data.team,
    time,
    timezones: transform(time, data.team.users)
  }
  return (<Team {...props}></Team>)
}

export default AppContainer
