import React from 'react'
import AppToolbar from './AppToolbar'
import TimezoneList from './TimezoneList'
import UserMenu from './UserMenu'

class Team extends React.Component {
  getUserMenu() {
    if (!this.props.user) return '';
    return <UserMenu {...this.props.user}
                     fixed={true} />;
  }

  render() {
    return (
      <div className="container app-container app-with-toolbar">
        <AppToolbar {...this.props} />
        <TimezoneList {...this.props}
                      showStats={true} />
      </div>
    );
  }
}

export default Team
