import React from 'react'
import classNames from 'classnames'
import Avatar from '../Avatar'
import './Person.css'
// var ActionCreators = require('../actions/actionCreators.js');

const getFirstName = (name) => name.split(' ')[0]

class Person extends React.Component {

  handleToggleSelected() {
    // ActionCreators.toggleSelectPerson(this.props.person._id);
  }

  handleAvatarLoadError() {
    // ActionCreators.fixBrokenAvatar(this.props.person._id);
  }

  render() {
    var person = this.props.person;

    var personClasses = 'person';
    if (this.props.isHighlighted) personClasses += ' person-highlight';

    var location = person.location === null ? '' :
                   person.location === '' ? 'Location needed!' :
                   person.location;

    var infoClasses = classNames('person-info', {
      'person-info-short': !person.location
    });

    return (
      <div className={personClasses}
           key={person._id}
           onClick={this.handleToggleSelected.bind(this)}>

        <Avatar
          avatar={person.avatar}
          name={person.name}
          onImageLoadError={this.handleAvatarLoadError.bind(this)}
        />

        <div className="person-name">
          {getFirstName(person.name)}
        </div>

        {/* <div className={infoClasses}>
          <p className="person-name">{person.name}</p>
          {location && <p className="person-city">{location}</p>}
        </div> */}
      </div>
    );
  }
}

export default Person
