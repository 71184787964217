import React from 'react'
import moment from 'moment-timezone'
import classNames from 'classnames'
import { getTopTimezone, groupIntoColumns } from '../../utils/layout'
import timeUtils from '../../utils/time'
import Person from '../Person'
import './Timezone.css'

class Timezone extends React.Component {
  isHighlighted(person) {
    if (!this.props.activeFilter)
      return false;
    return person.name.search(this.props.activeFilter) > -1;
  }
  render() {
    // We clone the time object itself so the this time is bound to
    // the global app time
    var localTime   = moment( this.props.time ).tz( this.props.timezone.tz );
    var fmtString   = timeUtils.getFormatStringFor(this.props.timeFormat);
    var displayTime = localTime ? localTime.format(fmtString) : 'Unknown';
    var offset      = localTime ? localTime.format('Z') : '??:??';
    var hour        = localTime ? localTime.hour() : 'unknown';
    const browserOffset = -(new Date().getTimezoneOffset())
    const isCurrentTime = this.props.timezone.people[0].tzOffset === browserOffset
    const topCity = getTopTimezone(this.props.timezone.people)

    const timezoneClasses = classNames('timezone timezone-hour-' + hour, {
      'timezone-current': isCurrentTime,
      'timezone-major': this.props.timezone.major
    })

    const columns = groupIntoColumns(this.props.timezone.people, this.props.peoplePerColumn)

    return (
      <div className={timezoneClasses}>
        <div className="timezone-header">
          <h3 className="timezone-time">{displayTime}</h3>
          <p className="timezone-name">{topCity}</p>
          <p className="timezone-offset">{offset}</p>
        </div>
        <div className="timezone-people">
          {columns.map((column, idx) => (
            <div className="timezone-people-column" key={"column-" + idx}>
              {column.map((person) =>
                <Person
                  key={person.id}
                  person={person}
                  isHighlighted={this.isHighlighted(person)}
                />
              )}
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default Timezone;
