import { gql } from '@apollo/client';

export const GET_TEAM = gql`
  query GetTeamAndUser {
    me {
      id
      name
      avatar
    }
    team {
      id
      name
      slug
      url
      users {
        id
        name
        avatar
        tz
        tzOffset
        tzLabel
      }
    }
  }
`
