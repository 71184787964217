import moment from 'moment-timezone'

function appendTime(time, person) {
  return {
    time: moment(time).tz(person.tz),
    utcOffset: person.tzOffset,
    ...person
  }
}

function sortByTimezone(a, b){
  return a.utcOffset - b.utcOffset;
}

function sortByNameAndId(a, b) {
  return a.name > b.name ? 1 :
         a.name !== b.name ? -1 :
         a._id > b._id ? 1 :
         -1;
}

function groupPeopleBy(people, field) {
  return people.reduce(function(zones, person) {
    var last = zones[ zones.length - 1 ];
    var value = last && last.people[0][field];

    if (last && value === person[field]) {
      last.people.push(person);
    } else {
      zones.push({
        tz: person.tz,
        people: [person]
      });
    }

    return zones;
  }, []);
}


 var transform = function(time, people, grouping) {

  // Append a moment date to each person
  const peopleWithTime = people.map(appendTime.bind(null, time))
  const peopleSorted = peopleWithTime.sort(sortByTimezone)

  // location or offset is default
  var field = grouping || 'utcOffset';
  var timezones = groupPeopleBy(peopleSorted, field);

  timezones.forEach(function(timezone){
    timezone.people.sort(sortByNameAndId);

    if (timezone.people.length / people.length > 0.2)
      timezone.major = true;
  });

  return timezones;

};

transform.userSort = sortByNameAndId;

export default transform
