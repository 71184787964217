import React from 'react'
import timeUtils from '../utils/time'

class Time extends React.Component {
  render() {
    var formatString = timeUtils.getFormatStringFor(this.props.timeFormat);
    var displayTime = this.props.time.format(formatString);
    return <span>{displayTime}</span>;
  }
}

export default Time;
