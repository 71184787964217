import React from 'react';
import { grommet } from 'grommet/themes'
import { deepMerge } from 'grommet/utils'
import { Avatar, Anchor, Nav, Grommet, Header, Text } from 'grommet'
// import Branding from './Branding'
// import UserMenu from './UserMenu'
// import TeamSearch from './TeamSearch'
// import TimeControl from './TimeControl'
import Time from './Time'
import { getAPIURL } from '../utils/urls'

const theme = deepMerge(grommet, {
  global: {
    colors: {
      brand: '#01579b'
    },
    font: {
      size: '16px',
      color: '#090909'
    },
  }
})

const AppToolbar = (props) => (
  <Grommet theme={theme}>
    <Header
      background="white"
      height="3em"
      pad="small"
    >

      <Nav direction="row" align="center">
        <Avatar
          src={'/images/brand-icon.png'}
          size="2em"
        />
        <Text weight="600">
          <Time time={props.time}/>
        </Text>
      </Nav>
      <Nav direction="row" align="center">
        <Anchor label="Billing" href="/billing" />
        <Anchor label="Log out" href={`${getAPIURL()}/logout`} />
        <Avatar
          alt={`${props.user.name}'s avatar`}
          title={props.user.name}
          src={props.user.avatar}
          size="2em"
        />
      </Nav>
    </Header>
  </Grommet>
)

export default AppToolbar

// class AppToolbar extends React.Component {

//   handleClickManage() {
//     // AppDispatcher.dispatchViewAction({
//     //   actionType: ActionTypes.SHOW_VIEW,
//     //   value: 'manage'
//     // });
//   }

//   handleChangeGrouping(e) {
//     // AppDispatcher.dispatchViewAction({
//     //   actionType: ActionTypes.CHANGE_GROUP_BY,
//     //   value: e.target.value
//     // });
//   }

//   render() {

//     return (
//       <div className="app-toolbar">

//         <div className="app-toolbar-section app-toolbar-left">

//           <div className="app-toolbar-branding">
//             <Branding
//               link={true}
//               minimal={true}
//             />
//           </div>

//           <div className="app-toolbar-main">
//             <TimeControl {...this.props} />
//           </div>

//         </div>

//         <div className="app-toolbar-section app-toolbar-center">
//           <h2 className="app-toolbar-name">
//             {this.props.team.name}
//           </h2>
//         </div>

//         <div className="app-toolbar-section app-toolbar-right">
//           <div className="app-toolbar-actions">

//             {this.props.isBeta && (
//               <select
//                 defaultValue="placeholder"
//                 onChange={this.handleChangeGrouping}
//               >
//                 <option value="placeholder" disabled="disabled">Group by</option>
//                 <option value="utcOffset">Timezone</option>
//                 <option value="location">Location</option>
//               </select>
//             )}

//             <TeamSearch people={this.props.people} />
//             { this.props.isAdmin && (
//               <button className="manage-team-button material-icons md-18 clear"
//                       title="Manage your team"
//                       onClick={this.handleClickManage}>
//                 settings
//               </button>
//             )}
//             {
//               this.props.user ?
//               <UserMenu {...this.props.user} /> :
//               <a href="/signup" className="button cta">
//                 Sign up
//               </a>
//             }

//           </div>
//         </div>

//       </div>
//     );
//   }

// }

// export default AppToolbar
