import React from 'react';
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  createHttpLink,
} from '@apollo/client';
import AppContainer from './components/AppConatiner'
import { getAPIURL } from './utils/urls'

const link = createHttpLink({
  uri: getAPIURL(),
  credentials: 'include',
})
const client = new ApolloClient({
  link,
  cache: new InMemoryCache()
})

function App() {
  return (
    <ApolloProvider client={client}>
      <AppContainer/>
    </ApolloProvider>
  );
}


export default App;
